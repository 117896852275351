/* Extra Margin */
.m-6 { margin: 3.5rem !important; }
.m-7 { margin: 5rem !important; }
.m-8 { margin: 6.5rem !important; }
.m-9 { margin: 8rem !important; }
.m-10 { margin: 10rem !important; }

.ml-6 { margin-left: 3.5rem !important; }
.ml-7 { margin-left: 5rem !important; }
.ml-8 { margin-left: 6.5rem !important; }
.ml-9 { margin-left: 8rem !important; }
.ml-10 { margin-left: 10rem !important; }

.mr-6 { margin-right: 3.5rem !important; }
.mr-7 { margin-right: 5rem !important; }
.mr-8 { margin-right: 6.5rem !important; }
.mr-9 { margin-right: 8rem !important; }
.mr-10 { margin-right: 10rem !important; }

.mt-6 { margin-top: 3.5rem !important; }
.mt-7 { margin-top: 5rem !important; }
.mt-8 { margin-top: 6.5rem !important; }
.mt-9 { margin-top: 8rem !important; }
.mt-10 { margin-top: 10rem !important; }

.mb-6 { margin-bottom: 3.5rem !important; }
.mb-7 { margin-bottom: 5rem !important; }
.mb-8 { margin-bottom: 6.5rem !important; }
.mb-9 { margin-bottom: 8rem !important; }
.mb-10 { margin-bottom: 10rem !important; }

/* Extra Padding */
.p-6 { padding: 3.5rem !important; }
.p-7 { padding: 5rem !important; }
.p-8 { padding: 6.5rem !important; }
.p-9 { padding: 8rem !important; }
.p-10 { padding: 10rem !important; }

.pl-6 { padding-left: 3.5rem !important; }
.pl-7 { padding-left: 5rem !important; }
.pl-8 { padding-left: 6.5rem !important; }
.pl-9 { padding-left: 8rem !important; }
.pl-10 { padding-left: 10rem !important; }

.pr-6 { padding-right: 3.5rem !important; }
.pr-7 { padding-right: 5rem !important; }
.pr-8 { padding-right: 6.5rem !important; }
.pr-9 { padding-right: 8rem !important; }
.pr-10 { padding-right: 10rem !important; }

.pt-6 { padding-top: 3.5rem !important; }
.pt-7 { padding-top: 5rem !important; }
.pt-8 { padding-top: 6.5rem !important; }
.pt-9 { padding-top: 8rem !important; }
.pt-10 { padding-top: 10rem !important; }

.pb-6 { padding-bottom: 3.5rem !important; }
.pb-7 { padding-bottom: 5rem !important; }
.pb-8 { padding-bottom: 6.5rem !important; }
.pb-9 { padding-bottom: 8rem !important; }
.pb-10 { padding-bottom: 10rem !important; }